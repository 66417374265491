import { Injectable } from '@angular/core';
import { OktaLoginService } from '../loginServices/OktaLogin.service';

@Injectable()
export class AuthService {
    constructor(private oktaLoginService: OktaLoginService) { }

    public isAuthenticated(): boolean {
        return this.oktaLoginService.isValidToken();
    }
}