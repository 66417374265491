<app-header></app-header>
<br /><br />
<div class="container" id="upload" style="max-width:min-content !important">
    <div id="upload-row" class="row justify-content-center align-items-center">
        <div id="upload-box">
            <div class="row align-items-center">
                <h2 style="font-weight: bold; text-align: center;text-decoration: underline;">
                    {{'HomeStaticContentHeader' | translate}}</h2>
                <span style="white-space: pre;">{{'HomeStaticContent' | translate}}</span>
            </div>
        </div>
    </div>
</div>