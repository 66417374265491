import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthService } from './auth.service';
import { OktaLoginService } from '../loginServices/OktaLogin.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private storage: LocalStorageService, private authService: AuthService, private oktaLoginService: OktaLoginService, private router: Router) { }
    canActivate(): boolean {
        let path = window.location.pathname;
        if (path.startsWith('/home')) {
            if(window.location.search.indexOf("code=") != -1 && sessionStorage.getItem('access_token') == null) {
                //extract code from url query string
                let start = window.location.search.indexOf("code=") + 5;
                let end = window.location.search.indexOf("&");
                let code = window.location.search.substring(start, end);
                this.oktaLoginService.getAccessToken(code, this.storage.get('state'));
            }
        } 

        if (!this.authService.isAuthenticated()) {
            //while redirecting from OKTA, if user is UnAuthorized navigate to UnAuthorized Page 
            if(window.location.search.includes("error=access_denied")){
                this.router.navigateByUrl('/un-authorized');
                return false;
            }   
            //No active session. Start Authorization process
            this.oktaLoginService.startAuthorization();
            return false;
        }
        return true;
    }
}