import { Component } from '@angular/core';
import { MonitorService } from 'src/Services/monitoring.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'GMFProtections-Marketing';

    constructor(private myMonitoringService: MonitorService) {
      // this is for logging verification into App insights. 
      this.myMonitoringService.logPageView();
    }
    
}
