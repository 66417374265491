import { NgIterable } from "@angular/core";

export class GlobalConstants {
    public static ackMsgFrench: string = "Ferme";
    public static ackMsg: string = "Ok";
    public static fileNotFound: string = "File not found!";
    public static fileNotFoundFrench: string = "fichier non trouvé!";
    public static oktaRedirectPath = "/home";
    public static oktaScope = "openid offline_access profile";
    public static oktaTimeout = 30 * 60 * 1000; //30 mins in millseconds
    public static buttonColorCode: string = "#005DAB";
    public static extPath: string = "gmprotection.ca";
    public static productTypesEN = [
        {
          key: "GAP", value: "GAP",
        },
        {
          key: "Protection_Plan",  value: "Protection Plan",
        },
        {
          key: "Prepaid_Maintenance", value: "Pre-Paid Maintenance",
        },
        {
          key: "Tire_Protection", value: "Tire Protection",
        },
        {
          key: "XS_Wear", value: "XS Wear Lease Protection",
        }
      ]
    
      public static productTypesFR = [
        {
          key: "GAP", value: "PEA",
        },
        {
          key: "Protection_Plan", value: "Plan de protection",
        },
        {
          key: "Prepaid_Maintenance", value: "Entretien prépayé",
        },
        {
          key: "Tire_Protection", value: "Protection des pneus",
        },
        {
          key: "XS_Wear", value: "Protection usure XS Pour la location",
        }
      ]
    
      public static productTypesPowerUpEN = [
        {
          key: "PowerUp_Protection", value: "PowerUP Protection Plan",
        }
      ]
    
      public static productTypesPowerUpFR = [
        {
          key: "PowerUp_Protection", value: "Plan de Protection PowerUP",
        }
      ]
      public static productTypesPowerUpPlusEN = [
        {
          key: "PowerUp_ProtectionPlus", value: "PowerUP+ Protection Plan",
        }
      ]
    
      public static productTypesPowerUpPlusFR = [
        {
          key: "PowerUp_ProtectionPlus", value: "Plan de Protection PowerUP+",
        }
      ]

      public static mediaTypesEN = [{
        key: "Brochures", value: "Brochures",
      },
      {
        key: "Desk_Mats",  value: "Desk Mats",
      },
    ]
  
    public static mediaTypesFR = [{
      key: "Brochures", value: "Brochures",
    },
    {
      key: "Desk_Mats",  value: "Tapis de bureau",
    },
  ]
  public static mmTypeLangMap:{[key:string] : NgIterable<any>}={
    "English": GlobalConstants.mediaTypesEN,
    "en":GlobalConstants.mediaTypesEN,
    "French": GlobalConstants.mediaTypesFR
}
}
