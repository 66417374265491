export const environment = {
    production: false,
     //@ts-ignore
     baseUrlInt:  window["env"].baseUrlInt,
     //@ts-ignore
     baseUrlExt:  window["env"].baseUrlExt,
     //@ts-ignore
     baseAppUrlInt:  window["env"].baseAppUrlInt,
      //@ts-ignore
      baseAppUrlExt:  window["env"].baseAppUrlExt,
     //@ts-ignore
     oktaClientId: window["env"].oktaClientId,
     //@ts-ignore
     oktaOAuthUrl: window["env"].oktaOAuthUrl,
     //@ts-ignore
     fileContentUrl: window["env"].fileContentUrl,
    appInsights: {
        //@ts-ignore
        instrumentationKey: window["env"].instrumentationKey        
    }
}