import { Component, NgIterable, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FileService } from './file.service';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import { GlobalConstants } from '../config/GlobalConstants';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})

export class ViewComponent implements OnInit {
  isVisible = false;
  public gmplogoimg!: string;
  public brandName!: string;
  public language!: string;
  public prodtype!: string;
  public mediaType!: string;
  pdfSource: any;
  fileNotFound: string;
  ackMsg: string;
  zoomSetting: number | string | undefined = 'auto';
  pdfBytes: any = [];

  defaultMessages() {
    this.fileNotFound = GlobalConstants.fileNotFound;
    this.ackMsg = GlobalConstants.ackMsg;
  }

  productTypeMap: NgIterable<any>;
  mediaTypeMap: NgIterable<any> = [];

  pageReset() {
    this.brandName = "";
    this.prodtype = "";
    this.language = "";
    this.mediaType ="";
    this.productTypeMap = [];
    this.mediaTypeMap = [];
  }

  productTypeReset() {
    this.prodtype = "";
    this.language = "";
  }

  public changeProductType(lang: string) {
    const languageMap: {[key: string]: {[key: string]: NgIterable<any>}} = {
      "English": {
        "PowerUP": GlobalConstants.productTypesPowerUpEN,
        "PowerUP+": GlobalConstants.productTypesPowerUpPlusEN
      },
      "en": {
        "PowerUP": GlobalConstants.productTypesPowerUpEN,
        "PowerUP+": GlobalConstants.productTypesPowerUpPlusEN
      },
      "French": {
        "PowerUP": GlobalConstants.productTypesPowerUpFR,
        "PowerUP+": GlobalConstants.productTypesPowerUpPlusFR
      }
    };
    
    if (this.brandName !== undefined  && languageMap[lang]) {
        if(languageMap[lang][this.brandName] === undefined && this.isEnglish(lang)) {
          this.productTypeMap = GlobalConstants.productTypesEN;    
        } else if(languageMap[lang][this.brandName] === undefined && this.isFrench(lang)) {
          this.productTypeMap = GlobalConstants.productTypesFR;    
        } else {
          this.productTypeMap = languageMap[lang][this.brandName];
        }
    } else {
      this.productTypeMap = [];
    }
  }

  public changeMMType(lang: string) {
  this.mediaTypeMap = GlobalConstants.mmTypeLangMap[lang];
}

  isEnglish(lang: string){
    if(lang === "English" || lang == "en") {
      return true;
    }
    return false;
  }

  isFrench(lang: string){
    if(lang === "French") {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    if (window.location.href.includes("view")) {
      this.isVisible = true;
    }
    this.gmplogoimg = this.activateImages();
    this.brandName = "";
    this.language = "";
    this.prodtype = "";
    this.mediaType ="";
    this.changeMMType(this.translate.getDefaultLang())
    this.defaultMessages();
  }


  constructor(
    private fileDownloadService: FileService,
    private router: Router, 
    public translate: TranslateService,
    private spinner: NgxSpinnerService
  ) {

    translate.addLangs(['English', 'French']);
    translate.setDefaultLang('English');
  }

  async viewPdf(viewForm: NgForm) {
    this.defaultMessages();
    if (viewForm.valid) {
      let access_token = sessionStorage.getItem('access_token'),
        headers: HttpHeaders = new HttpHeaders({
          "Authorization": "bearer " + access_token,
          "Accept": "application/pdf",
          "Content-Type": "application/pdf",
          "responseType": "pdf",
          "idToken": sessionStorage.getItem('id_token')!
        });
      let queryParams = new HttpParams();
      queryParams = queryParams.append("mmType", this.mediaType);
      queryParams = queryParams.append("language", this.language);
      queryParams = queryParams.append("brandName", this.brandName);
      queryParams = queryParams.append("productType", this.prodtype);
      this.spinner.show();
      let baseApiUrl = window.location.href.includes(GlobalConstants.extPath) ? environment.baseUrlExt + environment.fileContentUrl : environment.baseUrlInt + environment.fileContentUrl;
      this.fileDownloadService.download(headers, queryParams, baseApiUrl).subscribe({
        next: c => {
          const newBlob = new Blob([c], { type: 'application/pdf' });
          var x = document.getElementById('pdfDiv');
          if (x != null && x.style.display == 'none') {
            x.style.display = 'block';
          }
          this.pdfSource = newBlob;
        },
        error: error => {
          this.spinner.hide();
          var x = document.getElementById('pdfDiv');
          if (x != null) {
            x.style.display = 'none';
          }
          this.langValidation(GlobalConstants.fileNotFoundFrench, GlobalConstants.ackMsgFrench);
          this.FailureNotification(this.fileNotFound, this.ackMsg);
        },
        complete: () => {
          this.spinner.hide();
          console.log('Request complete');
        }
      },
      );
    }
  }

  langValidation(errorText: string, ackMsg: string) {
    if (document.documentElement.lang == "French") {
      this.fileNotFound = errorText;
      this.ackMsg = ackMsg;
    }
  }

  FailureNotification(successText: string, ackMsg: string) {
    Swal.fire({
      text: successText,
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: ackMsg,
      confirmButtonColor: GlobalConstants.buttonColorCode,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/home'])
      }
    });
  }

  public activateImages() {
    if (document.documentElement.lang == "French") {
      this.gmplogoimg = "/assets/images/gmProtectionsLogo_French.jpg";
    } else {
      this.gmplogoimg = "/assets/images/gmProtectionsLogo.jpg";
    }
    return this.gmplogoimg;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    document.documentElement.lang = lang;
    this.activateImages();
    this.pageReset();
    this.changeMMType(lang)
    var x = document.getElementById('pdfDiv');
    x!.style.display = 'none';
  }
}