<nav class="navbar navbar-expand-lg navbar-light border " style=" background-color:white !important" aria-label="logo display">
  <a id="gmplogo" class="navbar-brand" style="margin-left: 20px;"> <img id="gmplogoimg" [src]="gmplogoimg"
      alt="GMF logo"></a>
</nav>
<nav class="navbar navbar-expand-lg" aria-label="language select display">
  <div class="form-floating mb-3" style="width:120px;margin-left: 20px;">
    <select class="form-control form-select" #selectedLang (change)="switchLang(selectedLang.value)">
      <option *ngFor="let language of translate.getLangs()" [value]="language"
        [selected]="language === translate.currentLang">
        {{ language | translate}}
      </option>
    </select>
    <label class="col-form-label">Language</label>
  </div>
</nav>
