import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewComponent } from '../view/view.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [ViewComponent]
})

export class HeaderComponent implements OnInit {
  isVisible = false;
  public gmplogoimg!: string;

  ngOnInit(): void {
    if (window.location.href.includes("view")) {
      this.isVisible = true;
    }
    this.gmplogoimg = this.activateImages();
  }

  constructor(public translate: TranslateService, private viewComponent: ViewComponent
  ) {
    translate.addLangs(['English', 'French']);
    translate.setDefaultLang('English');
  }

  public activateImages() {
    if (document.documentElement.lang == "French") {
      this.gmplogoimg = "/assets/images/gmProtectionsLogo_French.jpg";
    } else {
      this.gmplogoimg = "/assets/images/gmProtectionsLogo.jpg";
    }
    return this.gmplogoimg;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    document.documentElement.lang = lang;
    this.activateImages();
    this.viewComponent.pageReset();
  }
  redirectToLogin() {
    //this.router.navigate(['/login']);
  }
}
