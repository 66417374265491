import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../assets/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }

  download(headers: any, queryParams: HttpParams, apiUrl: any): Observable<any> {
    return this.http.get<Blob>(apiUrl, {
      headers: headers,
      params: queryParams,
      responseType: 'blob' as 'json'
    }
    )
  }
}
