import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import { Token } from '../models/login.creds';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/assets/environments/environment';
import { GlobalConstants } from 'src/app/config/GlobalConstants';

@Injectable({
  providedIn: 'root',
})

export class OktaLoginService {

  oktaClientId = environment.oktaClientId;
  oktaOAuthUrl = environment.oktaOAuthUrl;
  oktaRedirectUrl: string;

  public isValidToken() {
    let access_token = sessionStorage.getItem('access_token');
    //check token expiryTime
    let expiryTime = parseInt(this.storage.get('expiryTime'));
    let currentTime = new Date().getTime()
    return access_token != null && currentTime < expiryTime;
  }

  constructor(private storage: LocalStorageService, private _http: HttpClient) { }

  public startAuthorization() {
    window.localStorage.clear();
    this.loadRedirectURI();
    this.authorize(GlobalConstants.oktaScope, this.oktaRedirectUrl);
  }

  public loadRedirectURI() {
    if (window.location.href.includes(GlobalConstants.extPath)) {
      this.oktaRedirectUrl = environment.baseAppUrlExt + GlobalConstants.oktaRedirectPath;
    } else {
      this.oktaRedirectUrl = environment.baseAppUrlInt + GlobalConstants.oktaRedirectPath;
    }
  }

  public authorize(scope: string, redirect_uri: string): any {
    this.loadRedirectURI();
    window.sessionStorage.clear();
    const state = this.strRandom(40);
    const codeVerifier = this.strRandom(128);
    this.storage.set('state', state);
    this.storage.set('codeVerifier', codeVerifier);
    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    const params = [
      'response_type=code',
      'state=' + state,
      'client_id=' + this.oktaClientId,
      'scope=' + scope,
      'code_challenge=' + codeChallenge,
      'code_challenge_method=S256',
      'redirect_uri=' + encodeURIComponent(redirect_uri),
    ];
    window.location.href = this.oktaOAuthUrl + '/authorize' + '?' + params.join('&');
  }
  
  private strRandom(length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    const randomValues = new Uint32Array(length);
    window.crypto.getRandomValues(randomValues);
    randomValues.forEach((value) => {
      result += characters.charAt(value % charactersLength);
    });
    return result;
  }

  public getAccessToken(code: string, state: string) {
    this.loadRedirectURI();
    if (state !== this.storage.get('state')) {
      alert('Invalid state');
      return;
    }
    const payload = new HttpParams()
      .append('grant_type', 'authorization_code')
      .append('code', code)
      .append('code_verifier', this.storage.get('codeVerifier'))
      .append('redirect_uri', this.oktaRedirectUrl)
      .append('client_id', this.oktaClientId);
    this._http.post<Token>(this.oktaOAuthUrl + '/token', payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).subscribe(response => {
      sessionStorage.setItem('refresh_token', response.refresh_token);
      sessionStorage.setItem('access_token', response.access_token);
      sessionStorage.setItem('id_token', response.id_token);
      this.storage.set('code', code);
      let currentTime = new Date().getTime()
      //currently setting expiry time to 30 mins
      let expiryTime = currentTime + GlobalConstants.oktaTimeout;
      this.storage.set('expiryTime', expiryTime);
      this.decodeJWT(sessionStorage.getItem('id_token')!);
      window.location.replace(this.oktaRedirectUrl);
    });
  }

  // This method is to decode the JWT id_token and retrieve the username and email
  decodeJWT(id_token: string) {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(id_token);
    sessionStorage.setItem('oktaEmail', decodedToken.preferred_username);
    sessionStorage.setItem('oktaUserName', decodedToken.name);
    sessionStorage.setItem('dealerName', decodedToken.DealerName);
    sessionStorage.setItem('legalName', decodedToken.LegalName);
    sessionStorage.setItem('dealerEmail', decodedToken.DealerEmail);
    sessionStorage.setItem('dealerRole', decodedToken.DealerRole);
    sessionStorage.setItem('street', decodedToken.StreetAddress);
    sessionStorage.setItem('city', decodedToken.City);
    sessionStorage.setItem('state', decodedToken.State);
    sessionStorage.setItem('zipCode', decodedToken.ZipCode);
    sessionStorage.setItem('country', decodedToken.Country);
    sessionStorage.setItem('primaryPhone', decodedToken.PrimaryPhone);
    sessionStorage.setItem('bacNumber', decodedToken.BACNumber);
    sessionStorage.setItem('dealerNumber', decodedToken.DealerNumber);
    sessionStorage.setItem('enrollmentStatus', decodedToken.EnrollmentStatus);
  }
}