import { ErrorHandler, Injectable } from "@angular/core";
import { MonitorService } from "./monitoring.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private logger: MonitorService) {
        super();
    }
    override handleError(error: Error) {
        this.logger.logException(error); 
        throw error;
    }
}