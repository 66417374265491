import { Component } from '@angular/core';

@Component({
  selector: 'app-marketinghome',
  templateUrl: './marketinghome.component.html',
  styleUrls: ['./marketinghome.component.css']
})
export class MarketinghomeComponent {

}
