<nav class="navbar navbar-expand-lg navbar-light border " style=" background-color:white !important" aria-label="logo display">
    <a id="gmplogo" class="navbar-brand" style="margin-left: 20px;"> <img id="gmplogoimg" [src]="gmplogoimg"
        alt="GMF logo"></a>
  </nav>
  <nav class="navbar navbar-expand-lg" aria-label="language select display">
    <div class="form-floating mb-3" style="width:120px;margin-left: 20px;">
      <select class="form-control form-select" #selectedLang (change)="switchLang(selectedLang.value)">
        <option *ngFor="let language of translate.getLangs()" [value]="language"
          [selected]="language === translate.currentLang">
          {{ language | translate}}
        </option>
      </select>
      <label class="col-form-label">{{'LangLabel' | translate}}</label>
    </div>
  </nav>
<div class="container" id="upload">
    <div id="upload-row" class="row">
        <div id="upload-column" class="panel">
            <div id="upload-box">
                <form action="submit" #viewForm="ngForm" action="submit" class="needs-validation"
                    (ngSubmit)="viewPdf(viewForm);">
                    <br />
                    <div class="row">
                        <div class="col-md-10">
                            <div class ="row">
                        <div class="col-md-3 form-floating mb-3">
                            <select class="form-control  form-select" name="media" [(ngModel)]="mediaType" id="mediaSelect"
                            #media="ngModel" [ngClass]="{ 'is-invalid': viewForm.submitted && media.invalid }"                              
                            required>
                            <option selected disabled value="">{{'SelectText' | translate}}</option>
                            <option *ngFor="let opt of mediaTypeMap" [value]="opt.key">{{opt.value}}</option>
                        </select>
                        <label id="medialabel" class="col-form-label">{{'MediaLabel' | translate}}</label>
                        <div *ngIf="media.errors?.['required'] && viewForm.submitted" style="color: red;">
                            <span id="mediareq">{{'MediaRequiredError' | translate}}</span>
                        </div>
                    </div>
                        <div class="col-md-3  form-floating mb-3">
                            <select class="form-control  form-select" name="brand" [(ngModel)]="brandName" id="brandSelect"
                                #brand="ngModel" [ngClass]="{ 'is-invalid': viewForm.submitted && brand.invalid }" (change)="changeProductType(selectedLang.value)"
                                (change) = "productTypeReset()" required>
                                <option selected disabled value="">{{'SelectText' | translate}}</option>
                                <option value="Buick">Buick</option>
                                <option value="Cadillac">Cadillac</option>
                                <option value="Chevrolet">Chevrolet</option>
                                <option value="GMC">GMC</option>
                                <option value="PowerUP">PowerUP</option>
                                <option value="PowerUP+">PowerUP+</option>
                            </select>
                            <label id="brandlabel" class="col-form-label">{{'BrandLabel' | translate}}</label>

                            <div *ngIf="brand.errors?.['required'] && viewForm.submitted" style="color: red;">
                                <span id="brandreq">{{'BrandRequiredError' | translate}}</span>
                            </div>
                        </div>

                        <div class="col-md-3 form-floating mb-3">
                            <select class="form-control form-select" name="lang" [(ngModel)]="language" #lang="ngModel"
                                [ngClass]="{ 'is-invalid': viewForm.submitted && lang.invalid }"  required>
                                <option selected disabled value="">{{'SelectText' | translate}}</option>
                                <option value="EN">{{'English' | translate}}</option>
                                <option value="FR">{{'French' | translate}}</option>
                            </select>
                            <label id="langlabel" class="col-form-label">{{'LangLabel' | translate}}</label>
                            <div *ngIf="lang.errors?.['required'] && viewForm.submitted" style="color: red;">
                                <span id="langreq">{{'LanguageRequiredError' | translate}}</span>
                            </div>
                        </div>
                        <div class="col-md-3 form-floating mb-3">
                            <select class="form-control form-select dropdown" name="product" [(ngModel)]="prodtype" #product="ngModel"
                              id="productSelect" [ngClass]="{ 'is-invalid': viewForm.submitted && product.invalid }" required>
                              <option selected disabled value="">{{'SelectText' | translate}}</option>
                              <option *ngFor="let opt of productTypeMap" [value]="opt.key">{{opt.value}}</option>
                            </select>
                            <label id="productlabel" class="col-form-label">{{'ProductLabel' | translate}}</label>
                            <div *ngIf="product.errors?.['required'] && viewForm.submitted" style="color: red;">
                              <span id="productreq">{{'ProductRequiredError' | translate}}</span>
                            </div>
                        </div>                  
                        </div>
                    </div>
                        <div class="col-md-2 align-items-center"><br />
                            <button id="viewbtn" style="margin-top: -30px;" type="submit"
                                class="btn btn-danger btn-block viewbtn">{{'viewbtnLabel' |
                                translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row" id="pdfDiv" style="display: none;">
        <ngx-extended-pdf-viewer [src]="pdfSource" [showDownloadButton]="true" [showOpenFileButton]="false"
        [showPrintButton]="true" [minifiedJSLibraries]="false" [(zoom)]="zoomSetting" [showDrawEditor]="false" [showTextEditor]="false"
            [showRotateButton]="false"></ngx-extended-pdf-viewer>
    </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "true"></ngx-spinner>
